<template>
  <div>
    <div v-if="marketPermission">
      <vs-row vs-type="flex" vs-justify="center" vs-align="center">
        <vs-col
          class="pr-1 pl-1 pb-2"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
        >
          <vx-card title-color="#fff" card-background="#000000">
            <vs-row>
              <h2 class="titles">{{ $t("Filigree_Rings") }}</h2>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <img
                  @click="goDesignMarket({ style: 'F' })"
                  class="bannerClass"
                  src="@/assets/images/dashboard/filigree1.jpg"
                  alt
                />
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>

        <vs-col
          class="pr-1 pl-1 pb-2"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-md="12"
          vs-sm="12"
        >
          <vx-card
            @click="goDesignMarket({ style: 'S' })"
            title-color="#fff"
            card-background="#000000"
          >
            <vs-row>
              <h2 class="titles">{{ $t("Surface_Rings") }}</h2>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <img
                  v-if="selectedSurface == 1"
                  src="@/assets/images/dashboard/Surface1.jpg"
                  class="bannerClass"
                  alt
                />
                <img
                  v-if="selectedSurface == 2"
                  src="@/assets/images/dashboard/Surface2.jpg"
                  class="bannerClass"
                  alt
                />
                <img
                  v-if="selectedSurface == 3"
                  src="@/assets/images/dashboard/Surface3.jpg"
                  class="bannerClass"
                  alt
                />

                <img
                  v-if="selectedSurface == 4"
                  src="@/assets/images/dashboard/Surface4.jpg"
                  alt
                  class="bannerClass"
                />
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>
      </vs-row>

      <vs-row vs-type="flex" vs-justify="center" vs-align="center">
        <vs-col
          class="pr-1 pl-1 pb-2"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-sm="12"
        >
          <vx-card
            @click="
              goDesignMarket({
                category: 1,
                weight_min: 0.13,
                weight_max: 0.89,
                gold_type: '14K',
              })
            "
            title-color="#fff"
            card-background="#000000"
          >
            <vs-row>
              <h2 class="titles">{{ $t("Light_Weight_Rings") }}</h2>

              <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <img
                  src="@/assets/images/dashboard/light1.jpg"
                  alt
                  class="bannerClass"
                />
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>

        <vs-col
          class="pr-1 pl-1 pb-2"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-sm="12"
        >
          <vx-card
            @click="goDesignMarket({ category: 1, spd: 7 })"
            title-color="#fff"
            card-background="#000000"
          >
            <vs-row>
              <h2 class="titles">{{ $t("Geometric_Rings") }}</h2>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <img
                  src="@/assets/images/dashboard/Geometric1.jpg"
                  class="bannerClass"
                  alt
                />
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>

        <vs-col
          class="pr-1 pl-1 pb-2"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-sm="12"
        >
          <vx-card
            @click="goDesignMarket({ enamel: 'true' })"
            title-color="#fff"
            card-background="#000000"
          >
            <vs-row>
              <h2 class="titles">{{ $t("Rings_with_Enamel") }}</h2>

              <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <img
                  class="bannerClass"
                  src="@/assets/images/dashboard/Enamel1.jpg"
                  alt
                />
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>
      </vs-row>

      <vs-row vs-type="flex" vs-justify="center" vs-align="center">
        <vs-col
          class="pr-1 pl-1 pb-2"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
        >
          <vx-card
            @click="goDesignMarket({ stone: 'true' })"
            title-color="#fff"
            card-background="#000000"
          >
            <vs-row>
              <h2 class="titles">{{ $t("Rings_with_Stones") }}</h2>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <img
                  v-if="selectedStone == 1"
                  src="@/assets/images/dashboard/Stones1.jpg"
                  alt
                  class="bannerClass"
                />
                <img
                  v-if="selectedStone == 2"
                  src="@/assets/images/dashboard/Stones2.jpg"
                  alt
                  class="bannerClass"
                />
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>

        <vs-col
          class="pr-1 pl-1 pb-2"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
        >
          <vx-card
            @click="goDesignMarket({ category: '9' })"
            card-background="#000000"
          >
            <vs-row>
              <h2 class="titles">{{ $t("Chains") }}</h2>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <img
                  class="bannerClass"
                  v-if="selectedChain == 1"
                  src="@/assets/images/dashboard/Chain1.jpg"
                  alt
                />
                <img
                  class="bannerClass"
                  v-if="selectedChain == 2"
                  src="@/assets/images/dashboard/Chain2.jpg"
                  alt
                />
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>
      </vs-row>
    </div>
    <div v-if="dentalPlatformPermission">
      <vs-row vs-type="flex" vs-justify="center" vs-align="center">
        <vs-col
          class="pr-1 pl-1 pb-2"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
        >
          <vx-card class="bannerClass" card-background="#fff">
            <vs-row>
              <div class="flex flex-wrap">
                <div
                  class="w-full xs:w-1/2 md:w-1/2 lg:w-1/4 p-2"
                  style="text-align: -webkit-center"
                >
                  <img src="@/assets/images/dashboard/Anatomic-crown.jpg" alt />
                  <p>{{ $t("Anatomic Crown") }}</p>
                </div>
                <div
                  class="w-full xs:w-1/2 md:w-1/2 lg:w-1/4 p-2"
                  style="text-align: -webkit-center"
                >
                  <img src="@/assets/images/dashboard/Inlays&Onlays.jpg" alt />
                  <p>{{ $t("Inley_Onley") }}</p>
                </div>

                <div
                  class="w-full xs:w-1/2 md:w-1/2 lg:w-1/4 p-2"
                  style="text-align: -webkit-center"
                >
                  <img src="@/assets/images/dashboard/veneer.jpg" alt />
                  <p>{{ $t("Veneer") }}</p>
                </div>
                <div
                  class="w-full xs:w-1/2 md:w-1/2 lg:w-1/4 p-2"
                  style="text-align: -webkit-center"
                >
                  <img
                    src="@/assets/images/dashboard/Bridge-Framework.jpg"
                    alt
                  />
                  <p>{{ $t("Bridge Framework") }}</p>
                </div>
              </div>
              <div class="flex flex-wrap">
                <div
                  class="w-full xs:w-1/2 md:w-1/2 lg:w-1/4 p-2"
                  style="text-align: -webkit-center"
                >
                  <img
                    src="@/assets/images/dashboard/Anatomic-simple-copings.jpg"
                    alt
                  />
                  <p>{{ $t("Anatomic Coping") }}</p>
                </div>
                <div
                  class="w-full xs:w-1/2 md:w-1/2 lg:w-1/4 p-2"
                  style="text-align: -webkit-center"
                >
                  <img src="@/assets/images/dashboard/Attachments.jpg" alt />
                  <p>{{ $t("Attachments") }}</p>
                </div>
                <div
                  class="w-full xs:w-1/2 md:w-1/2 lg:w-1/4 p-2"
                  style="text-align: -webkit-center"
                >
                  <img
                    src="@/assets/images/dashboard/Telescopic-Crowns.jpg"
                    alt
                  />
                  <p>{{ $t("Telescopic Crown") }}</p>
                </div>
                <div
                  class="w-full xs:w-1/2 md:w-1/2 lg:w-1/4 p-2"
                  style="text-align: -webkit-center"
                >
                  <img src="@/assets/images/dashboard/Parsials.jpg" alt />
                  <p>{{ $t("Parsial") }}</p>
                </div>
              </div>
              <div class="flex flex-wrap">
                <div
                  class="w-full xs:w-1/2 md:w-1/2 lg:w-1/4 p-2"
                  style="text-align: -webkit-center"
                >
                  <img src="@/assets/images/dashboard/All-on-Four.jpg" alt />
                  <p>{{ $t("All on X") }}</p>
                </div>
                <div
                  class="w-full xs:w-1/2 md:w-1/2 lg:w-1/4 p-2"
                  style="text-align: -webkit-center"
                >
                  <img src="@/assets/images/dashboard/Bars.jpg" alt />
                  <p>{{ $t("Bar") }}</p>
                </div>
                <div
                  class="w-full xs:w-1/2 md:w-1/2 lg:w-1/4 p-2"
                  style="text-align: -webkit-center"
                >
                  <img src="@/assets/images/dashboard/snap-on-smile.jpg" alt />
                  <p>{{ $t("Snap on Smile") }}</p>
                </div>
                <div
                  class="w-full xs:w-1/2 md:w-1/2 lg:w-1/4 p-2"
                  style="text-align: -webkit-center"
                >
                  <img src="@/assets/images/dashboard/Hybrid-bridge.jpg" alt />
                  <p>{{ $t("Hybrid Bridge") }}</p>
                </div>
              </div>
            </vs-row>
          </vx-card>
        </vs-col>
      </vs-row>
    </div>
    <div v-if="!marketPermission && !dentalPlatformPermission">
      <p>NovaFab Platform</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedSurface: 1,
      selectedChain: 1,
      selectedStone: 1,
    };
  },
  computed: {
    marketPermission() {
      return this.$store.state.designMarketPermission;
    },
    dentalPlatformPermission() {
      return this.$store.state.dentalPlatformPermission;
    },
  },
  methods: {
    goDentalPlatform(filter) {
      let params = JSON.parse(JSON.stringify(filter));
      this.$router.push({
        name: "dental_order",
        query: params,
      });
    },
    goDesignMarket(sendFilter) {
      let params = JSON.parse(JSON.stringify(sendFilter));
      this.$router.push({
        name: "design_market",
        query: params,
      });
    },
  },
  created() {
    this.selectedSurface = (Math.random() * Math.floor(3) + 1).toFixed(0);
    this.selectedChain = (Math.random() * Math.floor(1) + 1).toFixed(0);
    this.selectedStone = (Math.random() * Math.floor(1) + 1).toFixed(0);

    this.$router.push("/").catch((error) => {
      if (error.name != "NavigationDuplicated") {
        throw error;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.bannerClass {
  cursor: pointer;
  p {
    font-weight: 800;
  }
}

.titleDental {
  padding: 0px 20px 0px 20px;
  font-weight: 600;
}
.titles {
  color: #ffffff;
  padding: 0px 20px 0px 20px;
  font-weight: 600;
}

.vx-card .vx-card__collapsible-content .vx-card__body {
  padding: 0.5rem;
}

.vx-card .vx-card__collapsible-content img {
  display: block !important;
  width: 70% !important;
}
</style>
